<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ status.id ? 'Edit' : 'New' }} status
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to statuss list"
              @click="$router.push({ name: 'admin.status-management.index' })"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-9">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.name'] }}</label>
                        <input
                          v-model="status.status"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.order'] }}</label>
                        <input
                          v-model="status.order"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-9">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.section'] }}</label>
                        <v-select
                          v-model="status.section"
                          multiple
                          :options="allSections"
                        />
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.color'] }}</label>
                        <v-select
                          v-model="status.badge_class"
                          label="label"
                          :options="badge_classes"
                          :reduce="item => item.value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      statusId: this.$route.params.id,
      sending: false,
      errors: '',
      badge_classes: [
        { label: 'Green', value: 'success' },
        { label: 'Blue', value: 'info' },
        { label: 'Orange', value: 'warning' },
        { label: 'Red', value: 'danger' },
        { label: 'Grey', value: 'secondary' },
        { label: 'Light green', value: 'light-success' },
        { label: 'Light red', value: 'light-danger' },
        { label: 'Light grey', value: 'light-secondary' },
        { label: 'Light blue', value: 'light-info' },
        { label: 'Light orange', value: 'light-warning' },
      ],
      allSections: [],
    }
  },
  computed: {
    ...mapGetters({
      status: 'status/status',
      sections: 'status/sections',
      iPTypes: 'industrialPropertyParentTypes/items',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'masters.status')
    if (this.statusId) {
      await this.$store.dispatch('status/fetchStatus', this.statusId)
    } else {
      await this.$store.dispatch('status/clean')
    }

    await this.$store.dispatch('industrialPropertyParentTypes/fetch')
    const types = this.iPTypes.map(el => el.title ?? el.name)
    this.allSections = this.sections.concat(types)
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.statusId) {
          await this.$store.dispatch('status/update', { id: this.statusId, data: this.status })
        } else {
          await this.$store.dispatch('status/create', this.status)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.status.id) {
        Vue.swal('The status has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.status-management.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
  },

}
</script>
